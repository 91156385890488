$small: 375px;
$medium: 768px;
$large: 992px;
$xlarge: 1200px;
$widescreen: 1400px;
$off-white: #f6f9ff;
$min: min-width;
$max: max-width;
$slick-font-family: 'HelveticaNeue-Bold' !important;
$primary-brand-legacy-brand: #3498db;
$primary-brand-contrast-section: #293b61;
$page-bg-light-contrast: #e7f1ff;
$section-bg-light-grey: #f4f8ff;
$page-text-grey: #666666;
$page-text-page-background: #f3f4f6;
$page-text-white: #ffffff;
$buttons-secondary: #e8f1ff;
$buttons-disabled: #ebebeb;
$notification-affirmative: #40a434;
$notification-attention: #f97f15;
$notification-error: #e42323;
$error-bg: #feeeee;
$opportunity-co-founder: #ca2e55;
$opportunity-partnership: #003f91;
$opportunity-expert-advise: #f8971d;
$opportunity-mentor: #833fff;
$opportunity-connection: #0197f6;
$opportunity-investment: #34ba92;
$opportunity-volunteer: #cd44db;
$opportunity-other: #b9beca;
$buttons-primary: #106fff;
$grey-bg-light-eb: #ebebeb;
$white-bg-fff: #ffffff;
$text-black: #111111;
$black: #000000;
$blue-darkest--500: #1F2C49;
$blue-darkest: #1d2a44;
$blue-dark: #223251;
$transparent: transparent;
$gray-600: #95a5a6;
$gray-900: #f3f6f8;
$white: #fff;
$black-100: #111;
$black-200: #222;
$black-300: #333;
$black-400: #444;
$black-500: #555;
$black-600: #666;
$black-900: #999;
$success: #40a434;
$success-900: #f4faf3;
$success-light: rgba(64, 164, 52, 0.08);
$warning: #F39C12;
$lightred: #FF7276;
$danger: #E74C3C;
$orange : #f94d15;
$primary-900: #293b61;
$primary-800: #3a59c3;
$primary-700: #106eff;
$primary-500: #3498db;
$primary-300: #aecdfe;
$primary-100: #f1f6ff;
$primary: #106fff;
$primary-light: rgba(52, 152, 219, 0.5);
$secondary: #293a61;
$secondary-300: #106fff;
$secondary-400: #0966c2;
$secondary-500: #425374;
$secondary-900: #316ea4;
$secondary-1000: #e8f1ff;
$light-blue-100:rgba(232, 241, 255, 0.5);
$light-gray-dark:rgba(185, 190, 202, 0.5);
$dark: #767b87;
$dark--500: #868b95;
$dark--800: #e5eaef;
$dark-blue: #003f91;
$purple: #833fff;
$light-blue: #0197f6;
$dark-pink: #cd44db;
$co-founder: #ca2e55;
$connection: #0197f6;
$expert-advice: #f8971d;
$investment: #34ba92;
$mentor: #833fff;
$partnership: #003f91;
$volunteer: #cd44db;
$other: #b9beca;
$all: #fff;
$bg-gray-nav: #3C3F46;
$bg-gray-disabled: #ebebeb;
$text-gray-disabled: #9e9e9e;
$onboarding-input-gray: #11111129;
$onboarding-input-bg-gray: #D9D9D93D;
$onboarding-border-gray: #1111113D;
$open-status: #ffbc53;
$closed-status: #999999;
$in-demand-status: #ffbc53;
$active-status: #ffbc53;
$bg-yellow: #ffd000;
$in-demand-color: #f97f15;
$btn-secondary-5: rgba(232, 241, 255, 0.5);
$notification-affirmative: #40a434;
$linkedin-primary: #0a66c2;
$twitter-primary: #1fa1f1;
$dropdown-bg: #1a1a1a;
$secondary-light: #e9f1fe;
$gray-400: #282c31;
$navigation_hover: rgba(255,255,255,.75);
$secondary_dark_hover: rgba(255, 255, 255, 0.12);
$textbox-active-border: rgba(255, 255, 255, 0.35);
$textbox-active-shaddow: rgba(0, 0, 0, 0.12);
$black_bg_hover: #333333;
$black_bg_active: #555555;
$border-onboarding-secondary: rgba(17, 17, 17, 0.16);
$primary-variant: #2A72F6;
$spacer: 4px;
$em-spacer: 2em;
$bright-green: #5BA145;

$bg-light: #f3f4f6;
$bg-lightest: #fefefe;
$bg-dark-gray:#222222;
$bg-dark-onboarding:#23314e;
$btn-color--secondary: #666666;
$btn-bg--active: #5c94ba;
$bg-lightest--gray: #f9f9f9;

$typeform-color: rgb(232, 241, 255);
$badge-color: #344e54;
$primary-color--hover: #0f62e4;
$primary-color--active: #0c57cb;
$secondary-color--hover: #cfe3ff;
$secondary-color--active: #b8d6ff;
$special-btn: #FFCF87;
$special-btn-text: #260745;
$problem-box-1: #663597;
$problem-box-2: #531b8a;
$circle-number: #430880;
$dark-brown: #C15344;
$sky-blue: #428AC5;
$light-black: #435359;
$gray--light: #eaebed;
$gray--dark: #D6D6D6;


$font-family-bold: 'Helvetica Now Display Bold';
$font-family-semi-bold: 'Helvetica-Neue-Bold';
$font-family: 'Helvetica Now Display Regular';
$font-family-light: 'Helvetica-Neue-Light';

$font-size-sm: 12px;
$mmb-subheading-2: 14px;
$font-size-lg: 18px;

$color-muted: #aaa;
$border-color--light: rgba(151, 151, 151, 0.15);
$shadow-color: rgba(185, 190, 202, 0.5);
$light-white-tab: rgba(255, 255, 255, 0.08);

$dark-blue :#141a2b;
$light-white: #fffff0;
$light-color : rgba(255, 255, 255, 0.12);
$light-white-bg: rgba(255, 255, 255, 0.12);
$light-gray:rgba(185, 190, 202, 0.35);
$light-overlay: rgba(0, 0, 0, 0);
$dark-overlay: rgba(0, 0, 0, 0.5);
$white-opacity-xs: rgba(255, 255, 255, 0.65);
$modal-back-drop: rgba(41, 59, 97, 0.75);
$toggle-background: rgba(120, 120, 128, 0.16);
$dissable-input-onboarding: #3e4e70;
$white-lightest: rgba(255, 255, 255, 0.5);
$premium-bg: rgba(17, 17, 17, 0.75);
$light-border: rgba(255, 255, 255, 0.25);
$light-background-onboarding: rgba(255, 255, 255, 0.15);
$dissabled-textbox: rgba(255, 255, 255, 0.591);
$white-opacity-onbording: rgba(255, 255, 255, 0.45);
$about-section-bg-color: linear-gradient(0deg, #A8834E, #A8834E),
linear-gradient(203.12deg, rgba(240, 210, 105, 0.4) 10.45%, rgba(150, 131, 63, 0) 40.49%),
linear-gradient(0deg, #F9F9F9, #F9F9F9);

$about-section-bg-color-2: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
linear-gradient(0deg, #6410B8, #6410B8);

$about-problem-section: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
linear-gradient(0deg, #8710FF, #8710FF);

$home-inspired: linear-gradient(0deg, #252C34, #252C34),
radial-gradient(44.73% 44.73% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);



$dark-bg: #262b3b;
$modal-header-bg: rgba(234, 241, 254, 1);
$mobile_nav_hover_bg: rgba(255, 255, 255, 0.06);

$nav-hover-dark: #2e2e2e;

// Padding and margin utils
$spacer: 4px; // multiples of this unitscale
$spacer-range-start: 1;
$spacer-range-end: 100;
